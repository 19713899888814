import React from 'react';
import { Heading, Paragraph } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';

import * as grid from '../styles/Grid.styles';
import * as content from '../styles/Content.styles';
import * as styles from '../styles/WhatIsAbout.styles';
import { useOnScrollAnimation } from 'modules/app/hooks/useOnScrollAnimation';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import { motion } from 'framer-motion';

export const WhatIsAbout: React.FC = () => {
  const container = useOnScrollAnimation({ threshold: 0.3 });

  return (
    <article
      className="what-is-about"
      css={[grid.gridContainer, styles.container]}
    >
      <aside className="section__title">
        <Heading as="h1" size="tiny" css={content.contentSection}>
          <FormattedMessage id="aboutPage.roangoIsAboutSection.sectionTitle" />
        </Heading>
      </aside>

      <motion.section
        className="section__content"
        css={content.whatIsAbout}
        ref={container.ref}
        variants={STAGGER_CONTAINER}
        initial="hidden"
        animate={container.animation}
      >
        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature1" />
        </Paragraph>

        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature2" />
        </Paragraph>

        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature3" />
        </Paragraph>

        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature4" />
        </Paragraph>

        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature5" />
        </Paragraph>

        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature6" />
        </Paragraph>

        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature7" />
        </Paragraph>

        <Paragraph
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ type: 'keyframes', ease: 'easeInOut' }}
          useMotion
          size="xHuge"
        >
          <FormattedMessage id="aboutPage.roangoIsAboutSection.features.feature8" />
        </Paragraph>
      </motion.section>
    </article>
  );
};
