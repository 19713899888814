import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const contentSection = css`
  margin: 0;
  margin-block-end: var(--spacing-xlrg);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;

  @media ${breakpoints.medium} {
    margin-block-start: var(--spacing-xhuge);
    margin-block-end: 0;
  }
`;

export const contentHeading = css`
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xxLarge);
  line-height: var(--line-height-xxLarge);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xHuge);
    line-height: var(--line-height-xHuge);
  }
`;

export const contentHeadingSmall = css`
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxxLarge);
    line-height: var(--line-height-xxxLarge);
  }
`;

export const contentDescription = css`
  display: grid;
  grid-template-columns: minmax(auto, 528px);
  row-gap: var(--spacing-xxlrg);
`;

export const whatIsAbout = css`
  p {
    display: block;
    color: hsl(var(--color-text-1));
    text-transform: uppercase;
    font-size: var(--font-size-xLarge);
    line-height: var(--line-height-xLarge);

    @media ${breakpoints.large} {
      font-size: var(--font-size-xHuge);
      line-height: var(--line-height-xHuge);
      color: white;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    }
  }
`;
