import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const gridContainer = css`
  display: grid;

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);

    .section__title {
      grid-column: 1 / span 2;
    }

    .section__content {
      grid-column: 3 / -1;

      display: grid;
      grid-template-columns: repeat(10, 1fr);
      column-gap: var(--spacing-xxxlrg);

      .content__heading,
      .content__emphasise {
        grid-column: 1 / -2;
      }

      .content__description {
        grid-column: 1 / span 5;
      }

      .content__image {
        grid-column: 1 / -1;

        width: 100%;
      }
    }
  }

  &.what-is-about {
    margin-block-start: var(--spacing-huge);

    .section__content {
      grid-template-columns: 1fr;
    }
  }
`;
