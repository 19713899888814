import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { Navbar, Footer } from 'modules/app';
import { AboutOurStory } from './components/OurStory';
import { AboutWhyRoango } from './components/WhyRoango';
import { WhatIsAbout } from './components/WhatIsAbout';
import { AboutCompanyInfo } from './components/CompanyInfo';

import * as styles from './styles/Root.styles';

export const AboutPage: React.FC<RouteComponentProps> = () => {
  return (
    <article css={styles.root}>
      <Navbar />
      <section css={styles.wrapper}>
        <AboutOurStory />
        <AboutWhyRoango />
        <WhatIsAbout />
      </section>
      <AboutCompanyInfo />
      <Footer />
    </article>
  );
};
