import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const heading = css`
  margin-block-end: var(--spacing-huge);

  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xLarge);

  @media ${breakpoints.medium} {
    margin-block-end: calc(var(--unit) * 36);

    font-size: var(--font-size-xHuge);
    line-height: var(--line-height-xHuge);
  }
`;

export const headingSmall = css`
  margin-block-start: var(--spacing-huge);
  margin-block-end: var(--spacing-huge);

  @media ${breakpoints.medium} {
    margin-block-start: calc(var(--unit) * 30);
    margin-block-end: calc(var(--unit) * 20);
  }
`;

export const description = css`
  margin-block-end: var(--spacing-huge);

  @media ${breakpoints.medium} {
    margin-block-end: calc(var(--unit) * 20);
  }
`;

export const image = css`
  max-width: unset;
  width: calc(100% + var(--spacing-xlrg) * 2);
  margin-left: -1.25rem;

  @media ${breakpoints.medium} {
    max-width: 100%;
    width: initial;
    margin-left: unset;
  }
`;
