import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Paragraph, Heading } from 'modules/app';
import { StaticImage } from 'gatsby-plugin-image';

import * as grid from '../styles/Grid.styles';
import * as content from '../styles/Content.styles';
import * as styles from '../styles/OurStory.styles';
import { useOnScrollAnimation } from 'modules/app/hooks/useOnScrollAnimation';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_CONTROLLED } from 'style/animations.config';

export const AboutOurStory: React.FC = () => {
  const container = useOnScrollAnimation();

  return (
    <article css={[grid.gridContainer, styles.sectionContainer]}>
      <aside className="section__title">
        <Heading as="h1" size="tiny" css={content.contentSection}>
          <FormattedMessage id="aboutPage.ourStorySection.sectionTitle" />
        </Heading>
      </aside>

      <section className="section__content">
        <Heading
          as="h2"
          size="xHuge"
          className="content__heading"
          css={[content.contentHeading, styles.heading]}
        >
          <FormattedMessage id="aboutPage.ourStorySection.sectionHeading" />
        </Heading>

        <StaticImage
          className="content__image"
          css={styles.image}
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          src="../../../assets/images/about-story.jpg"
          alt="aboutPage.ourStorySection.sectionImageAlt"
        />

        <motion.section
          ref={container.ref}
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          initial="hidden"
          animate={container.animation}
          className="content__description"
          css={content.contentDescription}
        >
          <Paragraph size="medium" fontFamily="primary">
            <FormattedMessage id="aboutPage.ourStorySection.paragraphOne" />
          </Paragraph>

          <Paragraph size="medium" fontFamily="primary">
            <FormattedMessage id="aboutPage.ourStorySection.paragraphTwo" />
          </Paragraph>

          <Paragraph size="medium" fontFamily="primary">
            <FormattedMessage id="aboutPage.ourStorySection.paragraphThree" />
          </Paragraph>
        </motion.section>
      </section>
    </article>
  );
};
