import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const grid = css`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxxlrg);

  &.company-info-section__wrapper {
    max-width: 1312px;
    margin: 0 auto;
    padding-left: var(--spacing-xlrg);
    padding-right: var(--spacing-xlrg);
    box-sizing: content-box;

    .footer__divider {
      width: 100%;
    }
  }

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);

    &.company-info-section__wrapper {
      .company-info-section__title {
        grid-column: 1 / span 2;
      }

      .company-info-section__business-info {
        grid-column: 7 / span 2;
      }

      .company-info-section__financial-info {
        grid-column: 10 / span 3;
      }

      .footer__divider {
        grid-column: 1 / -1;
        width: 100%;
      }
    }
  }
`;

export const companyInfoSection = css`
  background: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));
  padding-top: var(--spacing-xhuge);
  margin-top: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    padding-top: calc(var(--spacing-xhuge) * 2);
  }

  .footer__divider {
    margin-top: var(--spacing-xhuge);

    @media ${breakpoints.medium} {
      margin-top: calc(var(--spacing-xhuge) * 2.4);
    }
  }
`;

export const financialInfo = css`
  color: hsl(var(--color-borderDark-5));

  p:nth-of-type(4) {
    margin-bottom: var(--spacing-xxlrg);
  }
`;
