import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, Paragraph } from 'modules/app';
import React from 'react';

import * as styles from '../styles/CompanyInfo.styles';

export const AboutCompanyInfo: React.FC = () => {
  return (
    <article
      className="about__company-info-section"
      css={styles.companyInfoSection}
    >
      <div className="company-info-section__wrapper" css={styles.grid}>
        <Heading as="h2" size="large" className="company-info-section__title">
          <FormattedMessage id="footer.companyInfoSection.heading" />
        </Heading>

        <section className="company-info-section__business-info">
          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.businessInfo.name" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.businessInfo.address.street" />
          </Paragraph>
          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.businessInfo.address.city" />
            ,&nbsp;
            <FormattedMessage id="footer.companyInfoSection.businessInfo.address.country" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.businessInfo.pin" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.businessInfo.vatId" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.businessInfo.regId" />
          </Paragraph>
        </section>

        <section
          className="company-info-section__financial-info"
          css={styles.financialInfo}
        >
          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.financialInfo.accountType" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.financialInfo.bankName" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.financialInfo.iban" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.financialInfo.swift" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.financialInfo.registeredCapital" />
            :&nbsp;
            <FormattedMessage id="footer.companyInfoSection.financialInfo.registeredCapitalAmount" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.financialInfo.registerLocation" />
          </Paragraph>

          <Paragraph size="base" fontFamily="primary">
            <FormattedMessage id="footer.companyInfoSection.financialInfo.regNo" />
            :&nbsp;
            <FormattedMessage id="footer.companyInfoSection.financialInfo.regNoValue" />
          </Paragraph>
        </section>
        <hr className="footer__divider" />
      </div>
    </article>
  );
};
