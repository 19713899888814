import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const sectionContainer = css`
  margin-block-start: calc(var(--unit) * 9);
  margin-block-end: calc(var(--unit) * 20);

  @media ${breakpoints.medium} {
    margin-block-start: calc(var(--unit) * 38);
    margin-block-end: calc(var(--unit) * 30);
  }
`;

export const heading = css`
  margin-block-end: var(--spacing-huge);

  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xLarge);

  @media ${breakpoints.medium} {
    margin-block-end: calc(var(--unit) * 36);

    font-size: var(--font-size-xHuge);
    line-height: var(--line-height-xHuge);
  }
`;

export const image = css`
  margin-block-end: var(--spacing-huge);
  max-width: unset;
  width: calc(100% + var(--spacing-xlrg) * 2);
  margin-left: -1.25rem;

  @media ${breakpoints.medium} {
    margin-block-end: calc(var(--unit) * 20);
    max-width: 100%;
    width: initial;
    margin-left: unset;
  }
`;
